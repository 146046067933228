import React, { useCallback, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { rem } from 'polished'
import useOffer from 'hooks/Offers/useOffer'
import SearchForm from 'components/Search/SearchForm'
import { usePlacesBySearchPhrase } from 'hooks/usePlace'
import { HOTEL_SEARCH_TYPEAHEAD_TYPES } from 'constants/search'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import { GLOBAL_SEARCH_INITIAL_STATE, GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import GlobalSearchContextProvider from 'contexts/GlobalSearch/GlobalSearchContextProvider'
import { useGlobalHotelsSearchContext } from 'hooks/GlobalSearch/GlobalSearchVerticals/useGlobalHotelsSearch'

const offerPagesRoutes = [
  '/:regionCode/offer/:offerId',
  '/:regionCode/offer/:offerSlug/:offerId',
]

const SearchContainer = styled.div`
  position: relative;
  padding: ${rem(16)};
`

const LoadingContainer = styled.div`
  height: ${rem(50)};
`

function SubHeaderBarSearch() {
  const match = useRouteMatch({ path: offerPagesRoutes, exact: true })
  const offerId = match && 'offerId' in match.params ? match.params.offerId as string : undefined
  const [offer] = useOffer<App.Offer>(offerId)

  const { globalHotelsSearchDispatch, globalHotelsSearchState } = useGlobalHotelsSearchContext({
    ...GLOBAL_SEARCH_INITIAL_STATE,
    eventAnalytics: {
      contextLocation: 'search-list',
    },
  })

  const [searchItems, fetchingSearch] = usePlacesBySearchPhrase(offer?.property?.name ?? '')

  const setSearchItem = useCallback((searchItem: App.SearchItem) => {
    globalHotelsSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
  }, [globalHotelsSearchDispatch])

  useEffect(() => {
    if (searchItems.length && searchItems[0].value !== globalHotelsSearchState.searchItem?.value) {
      setSearchItem(searchItems[0])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItems])

  return <GlobalSearchContextProvider state={globalHotelsSearchState} dispatch={globalHotelsSearchDispatch}>
    <SearchContainer>
      {fetchingSearch && <LoadingContainer>
        <LoadingIndicator floating />
      </LoadingContainer>}
      {!fetchingSearch && <SearchForm
        submitOnApply
        typeaheadTypes={HOTEL_SEARCH_TYPEAHEAD_TYPES}
      />}
    </SearchContainer>
  </GlobalSearchContextProvider>
}

export default SubHeaderBarSearch
