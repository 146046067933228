import { matchPath, useLocation } from 'react-router'
import { useAppSelector } from 'hooks/reduxHooks'
import { OFFER_TYPE_HOTEL } from 'constants/offer'

const offerPagesRoutes = [
  '/:regionCode/offer/:offerId',
  '/:regionCode/offer/:offerSlug/:offerId',
]

function useIsFlashOfferPage() {
  const pathname = useLocation().pathname
  const offers = useAppSelector(state => state.offer.offers)
  const offerSummaries = useAppSelector(state => state.offer.offerSummaries)
  const offerPath = matchPath(pathname, { path: offerPagesRoutes, exact: true })

  if (offerPath && 'offerId' in offerPath.params) {
    const offerId = offerPath.params.offerId as string
    const offer = offers[offerId] || offerSummaries[offerId]
    const isFlashOffer = offer?.type === OFFER_TYPE_HOTEL
    return isFlashOffer
  }

  return false
}

export default useIsFlashOfferPage
