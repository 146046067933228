import LayoutContainer from 'components/Common/LayoutContainer'
import React from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import NavigationNavItems from './NavigationHeader/NavigationNavItems'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useIsHomepage from 'home/hooks/isHomepage'
import { useLocation } from 'react-router'
import { getSearchVerticalFromPathname } from 'lib/search/searchUtils'
import { SEARCH_VERTICALS } from 'constants/search'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import useIsFlashOfferPage from 'hooks/OfferPage/useIsFlashOfferPage'
import SubHeaderBarSearch from './SubHeaderBarSearch'

const NavigationSubHeaderWrapper = styled.div`
  background: ${props => props.theme.palette.neutral.default.eight};

  &.bordered {
    border-bottom: 1px solid ${props => props.theme.palette.neutral.default.five};
  }
`

const SubHeaderBar = React.forwardRef<HTMLDivElement>((_, ref) => {
  const isHomepage = useIsHomepage()
  const pathname = useLocation().pathname

  // Desktop Hotel Search AB test
  const isHotelSearch = getSearchVerticalFromPathname(pathname) === SEARCH_VERTICALS.HOTELS
  const navBarSearchDesktopVariant = useOptimizelyExperiment(OptimizelyExperiments.croNavBarSearchDesktop, isHotelSearch)

  // Desktop Flash Offer page Search AB test
  const isFlashOfferPage = useIsFlashOfferPage()
  const navBarFlashOfferPageDesktopVariant = useOptimizelyExperiment(OptimizelyExperiments.croNavBarFlashOfferPageDesktop, isFlashOfferPage)

  const hideNavBar = (isHotelSearch && navBarSearchDesktopVariant === 'variant_2') ||
    (isFlashOfferPage && navBarFlashOfferPageDesktopVariant === 'variant_2')

  const showHotelSearch = isFlashOfferPage && navBarFlashOfferPageDesktopVariant === 'variant_1'
  const hideBorderBottom = isFlashOfferPage && navBarFlashOfferPageDesktopVariant === 'variant_2'

  return <NavigationSubHeaderWrapper className={cn('no-print', { bordered: !hideBorderBottom })} ref={ref}>
    {isHomepage && (
      <LayoutContainer>
        <NavigationNavItems />
      </LayoutContainer>
    )}
    {!isHomepage && !hideNavBar && (
      <CSSBreakpoint min="tablet">
        <LayoutContainer>
          <NavigationNavItems />
        </LayoutContainer>
      </CSSBreakpoint>
    )}
    {showHotelSearch && (
      <CSSBreakpoint min="desktop">
        <SubHeaderBarSearch />
      </CSSBreakpoint>
    )}
  </NavigationSubHeaderWrapper>
})

export default SubHeaderBar
