import { toggleRegionDropdown } from 'actions/UiActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import Divider from 'components/Luxkit/Divider'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import Flag from 'components/Luxkit/Flag'
import config from 'constants/config'
import setRegionCookie from 'cookies/setRegionCookie'
import { useAppDispatch } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import React, { MouseEvent, useCallback, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import RegionListItem from './RegionListItem'
import { REGION_DROPDOWN_START_ORDER } from 'constants/config/region'

function categoriseRegions(): {
  priorityRegions: Array<App.RegionSummary>
  restOfRegions: Array<App.RegionSummary>
  } {
  const priorityRegions: Array<App.RegionSummary> = []
  const restOfRegions: Array<App.RegionSummary> = []

  config.regions.forEach(region => {
    if (new Set(REGION_DROPDOWN_START_ORDER).has(region.code)) {
      priorityRegions.push(region)
    } else {
      restOfRegions.push(region)
    }
  })

  return { priorityRegions, restOfRegions }
}

const { priorityRegions, restOfRegions } = categoriseRegions()

interface Props {
  location: any;
  currentRegionCode: string;
  currentRegionName: string;
  className?: string;
}

function NavigationHeaderRegionMenu(props: Props) {
  const {
    currentRegionCode,
    location,
    className,
  } = props

  const { value: isOpen, toggle: toggleDropdown, off: closeDropdown } = useToggle(false)
  const triggerRef = useRef(null)
  const dispatch = useAppDispatch()

  const handleRegionClick = useCallback((region: App.RegionSummary, event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch(toggleRegionDropdown())
    if (region.code !== currentRegionCode) {
      setRegionCookie(region.code)
      window.location.href = location.pathname.replace(/^\/[a-z]+/, '/' + region.code.toLowerCase()) + location.search
    }
  }, [currentRegionCode, location.pathname, location.search, dispatch])

  const selectedRegion = useMemo(() => config.regions.find(region => (region.code === currentRegionCode)), [currentRegionCode])
  const selectedRegionFlagToDisplay = currentRegionCode === 'NL' ? 'EU' : currentRegionCode

  if (config.regions.length < 2) {
    return null
  }

  return (<>
    <TextButton
      className={className}
      kind="tertiary"
      variant="dark"
      aria-label="Select your region"
      aria-expanded={isOpen}
      aria-haspopup
      onClick={toggleDropdown}
      ref={triggerRef}
      startIcon={selectedRegion ? <Flag countryCode={selectedRegionFlagToDisplay} /> : undefined}
      endIcon={selectedRegion ? <DropdownChevron open={isOpen} /> : undefined}
    >
      {selectedRegion?.currencyCode ?? currentRegionCode}
    </TextButton>
    <DropdownList
      id="region-menu-dropdown"
      size="S"
      open={isOpen}
      onClose={closeDropdown}
      anchorRef={triggerRef}
      triggerRef={triggerRef}
    >
      {priorityRegions.map(region => <RegionListItem
        key={region.code}
        region={region}
        onRegionClick={handleRegionClick}
      />)}
      <Divider kind="secondary" />
      {restOfRegions.map(region => <RegionListItem
        key={region.code}
        region={region}
        onRegionClick={handleRegionClick}
      />)}
    </DropdownList>
  </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    location: state.router.location,
    currentRegionCode: state.geo.currentRegionCode,
    currentRegionName: state.geo.currentRegionName,
  }
}

export default connect(mapStateToProps)(NavigationHeaderRegionMenu)
